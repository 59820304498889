import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import ApproxAPIClient from '../../../ApproxAPIClient'
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton'
import InputLabel from '@mui/material/InputLabel';


export default function CreatePricingLibraryDialog({ createPricingLibraryIsOpen, setCreatePricingLibraryIsOpen, handleCreatePricingLibrarySuccess }) {
    const approxAPIClient = ApproxAPIClient();
    const [isLoading, setIsLoading] = useState(false);

    const handleCreatePricingLibraryClose = () => {
        setCreatePricingLibraryIsOpen(false);
    };

    return <Dialog
        open={createPricingLibraryIsOpen}
        onClose={handleCreatePricingLibraryClose}
        fullWidth
        PaperProps={{
            component: 'form',
            onSubmit: (event) => {
                event.preventDefault();
                const formData = new FormData(event.currentTarget);
                const formJson = Object.fromEntries(formData.entries());
                setIsLoading(true);
                approxAPIClient.createPricingLibrary(formJson).then((res) => {
                    if (res.isSuccessful === true) {
                        handleCreatePricingLibrarySuccess();
                    } else {
                        alert(res.error);
                    }

                    setIsLoading(false);
                    handleCreatePricingLibraryClose();
                })
            },
        }}
    >
        <DialogTitle>Create Pricing Library</DialogTitle>
        {
            !isLoading ? <>
                <DialogContent>
                    <InputLabel id="pricingLibraryName-label">Pricing Library Name</InputLabel>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="pricingLibraryName"
                        name="name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreatePricingLibraryClose}>Cancel</Button>
                    <Button type="submit">Create</Button>
                </DialogActions>


            </> : <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
        }
    </Dialog>
}