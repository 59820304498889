const PricingStateType = {
    None: 0,
    Draft: 1,
    Validated: 2,
};

const PricingStateTypeDict = {
    None: "-",
    Draft: "Draft",
    Validated: "Validated",
}

const PricingStateValTypeDict = {
    0: PricingStateTypeDict["None"],
    1: PricingStateTypeDict["Draft"],
    2: PricingStateTypeDict["Validated"],
}

export { PricingStateType, PricingStateTypeDict, PricingStateValTypeDict };