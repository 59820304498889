import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import ApproxAPIClient from '../../../ApproxAPIClient'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import WorkOrderType from '../../../constants/WorkOrderType'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CalculateIcon from '@mui/icons-material/Calculate';
import DrawIcon from '@mui/icons-material/Draw';

export default function Assignees({ workTypeID, hidden, workOrderListRenderID, handleChangeAssigneeSuccess }) {
    const approxAPIClient = ApproxAPIClient();
    const [workOrders, setWorkOrders] = useState();
    const [users, setUsers] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const projectID = searchParams.get("projectID");
    const [selectedWorkOrderIDs, setSelectedWorkOrderIDs] = useState([]);
    const [selectedUserID, setSelectedUserID] = useState();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedWorkOrderType, setSelectedWorkOrderType] = useState();

    const handleUserListSelect = (userID) => {
        setSelectedUserID(userID);
    }

    const handleTabSelect = (val) => {
        if (isLoading) {
            return;
        }
        setSelectedTabIndex(val);
    }

    const handleToggleCheckBox = (workOrderID) => () => {
        const index = selectedWorkOrderIDs.indexOf(workOrderID);
        if (index === -1) {
            setSelectedWorkOrderIDs([workOrderID, ...selectedWorkOrderIDs]);
        } else {
            const otherWorkOrderIDs = [...selectedWorkOrderIDs].filter(x => x !== workOrderID);
            setSelectedWorkOrderIDs([...otherWorkOrderIDs]);
        }
    };

    const handleSelectAll = () => {
        if (selectedWorkOrderIDs.length < workOrders.length) {
            const allWorkOrderIDs = workOrders.map(x => x.id);
            setSelectedWorkOrderIDs(allWorkOrderIDs);
        } else {
            setSelectedWorkOrderIDs([]);
        }
    }

    const handleAssignClick = async () => {
        const payload = {
            assignedUserID: selectedUserID,
            workOrderIDs: selectedWorkOrderIDs
        }

        setIsLoading(true);
        let res = await approxAPIClient.assignWorkOrdersToUser(payload);
        if (res.isSuccessful === true) {
            handleChangeAssigneeSuccess();
        } else {
            alert(res.error);
        }
        setIsLoading(false);

    }

    const handleClearClick = async () => {
        const payload = {
            assignedUserID: null,
            workOrderIDs: selectedWorkOrderIDs
        }

        setIsLoading(true);
        let res = await approxAPIClient.assignWorkOrdersToUser(payload);
        if (res.isSuccessful === true) {
            handleChangeAssigneeSuccess();
        } else {
            alert(res.error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (selectedTabIndex === 0) {
            setSelectedWorkOrderType(WorkOrderType.Takeoff);
        } else if (selectedTabIndex === 1) {
            setSelectedWorkOrderType(WorkOrderType.Drawing);
        }
    }, [selectedTabIndex])

    useEffect(() => {
        if (!workTypeID || !projectID || !selectedWorkOrderType) {
            return;
        }

        setIsLoading(true);

        approxAPIClient.fetchWorkOrdersByWorkTypeIDProjectID(workTypeID, projectID, selectedWorkOrderType).then((response) => {
            if (response.isSuccessful === true) {
                setWorkOrders(response.data);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        });

    }, [approxAPIClient, workTypeID, projectID, workOrderListRenderID, selectedWorkOrderType])

    useEffect(() => {
        setIsLoading(true);

        approxAPIClient.fetchAllTenantUsers().then((response) => {
            if (response.isSuccessful === true) {
                console.log(response.data);
                setUsers(response.data);
            } else {
                alert(response.error);
            }

        }).then(() => {
            setIsLoading(false);
        });

    }, [approxAPIClient])

    return (
        <Grid container spacing={1} hidden={hidden} style={{ height: '100%' }}>
            <Grid item xs={8} style={{ height: '100%' }}>
                <Paper style={{ height: '100%' }}>
                    <List
                        component="nav"
                        aria-label="work group type list"
                        style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent='space-between'>
                                    <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                        Work Orders
                                    </Typography>

                                    {workOrders && selectedWorkOrderIDs && workOrders.length > 0 ? <Box
                                        onClick={() => handleSelectAll()}>
                                        <Checkbox
                                            sx={{ height: "100%" }}
                                            label='Select All'
                                            checked={selectedWorkOrderIDs.length === workOrders.length}

                                        />
                                    </Box> : <></>}
                                </Stack>
                                <Tabs
                                    value={selectedTabIndex}
                                    onChange={(event, newValue) => handleTabSelect(newValue)}
                                    disabled={isLoading}
                                    variant="fullWidth"
                                    style={{ height: '7vh' }}
                                >
                                    <Tab icon={<CalculateIcon fontSize='small' />} label="Takeoff" />
                                    <Tab icon={<DrawIcon fontSize='small' />} label="Drawing" />
                                </Tabs>
                            </ListSubheader>
                        }
                    >
                        {!workTypeID ? <Typography sx={{ height: "10%", lineHeight: "200%", textAlign: 'center' }} color="text.secondary" textAlign="left">
                            Please select a work type
                        </Typography> :
                            !isLoading && users && workOrders ? workOrders.map(x => {

                                let parentList = [];
                                let parent = x.property.parent;

                                while (parent !== null) {
                                    parentList.push(`${parent.code} ${parent.name}`);
                                    parent = parent.parent;
                                }

                                parentList.reverse();

                                return (
                                    <ListItemButton
                                        onClick={handleToggleCheckBox(x.id)}
                                        selected={selectedWorkOrderIDs.indexOf(x.id) !== -1}
                                        key={x.id}
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selectedWorkOrderIDs.indexOf(x.id) !== -1}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${x.property.code} ${x.property.name}`}
                                            primaryTypographyProps={{
                                                fontSize: 15,
                                                fontWeight: 'medium',
                                                lineHeight: '50px',
                                                mb: '2px',
                                                color: 'black'
                                            }}
                                            secondary={parentList.join('/')}
                                            secondaryTypographyProps={{
                                                fontWeight: 'medium',
                                                mb: '2px',
                                                textAlign: 'left',
                                            }}
                                            sx={{ width: '80%' }}
                                        />
                                        <ListItemText
                                            primary={users.find(a => a.user_id === x.assignedUserID)?.name ?? 'None'}
                                            primaryTypographyProps={{
                                                fontSize: 12,
                                                fontWeight: 'medium',
                                                lineHeight: '110%',
                                                mb: '2px',
                                                color: 'white',
                                                textAlign: 'left'
                                            }}
                                            secondary={users.find(a => a.user_id === x.assignedUserID)?.email ?? ''}
                                            secondaryTypographyProps={{
                                                fontWeight: 'medium',
                                                fontSize: 8,
                                                mb: '2px',
                                                textAlign: 'left',
                                                color: 'lightgray',
                                            }}
                                            sx={{ width: '20%', backgroundColor: users.find(a => a.user_id === x.assignedUserID) ? 'grey' : '#d32f2f', height: '100%', padding: 2 }}
                                        />
                                    </ListItemButton>)
                            }

                            ) : <Box sx={{ width: '100%', padding: '5%' }}>
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                                <Skeleton animation="wave" />
                            </Box>}
                    </List>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper style={{ height: '60%' }}>
                    <List
                        component="nav"
                        aria-label="user list"
                        style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                <Stack spacing={1} direction="row" sx={{ width: '100%' }} justifyContent='space-between'>
                                    <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                        Users
                                    </Typography>
                                </Stack>
                            </ListSubheader>
                        }
                    >
                        {!isLoading && users ? users.map(x => {
                            return (
                                <ListItemButton
                                    onClick={() => handleUserListSelect(x.user_id)}
                                    selected={selectedUserID === x.user_id}
                                    key={x.user_id}
                                >
                                    <ListItemText
                                        primary={x.name}
                                        primaryTypographyProps={{
                                            fontSize: 15,
                                            fontWeight: 'medium',
                                            lineHeight: '50px',
                                            mb: '2px',
                                            color: 'black'
                                        }}
                                        secondary={x.email}
                                        secondaryTypographyProps={{
                                            fontWeight: 'medium',
                                            mb: '2px',
                                            textAlign: 'left'
                                        }}
                                    />
                                </ListItemButton>)
                        }

                        ) : <Box sx={{ width: '100%', padding: '5%' }}>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                        </Box>
                        }
                    </List>
                </Paper>
                <Box style={{ height: '40%', padding: 5, marginTop: 5 }}>
                    <Button
                        color='success'
                        variant='contained'
                        fullWidth
                        disabled={isLoading || !selectedUserID || selectedWorkOrderIDs.length === 0}
                        onClick={handleAssignClick}
                    > Assign To User
                    </Button>
                    <Button color='error' variant='contained' fullWidth disabled={isLoading || selectedWorkOrderIDs.length === 0} sx={{ marginTop: 1 }} onClick={handleClearClick}>Clear Assignees</Button>
                </Box>
            </Grid>
        </Grid>

    )
}