import React, { useState, useEffect } from 'react';
import {
    Paper,
    Typography,
    Stack,
    Box,
    Collapse,
    Table,
    Chip,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    CircularProgress,
} from '@mui/material';
import ApproxAPIClient from '../../../ApproxAPIClient';
import { PricingUnitValTypeDict } from '../../../constants/PricingUnitType';
import { PricingStateValTypeDict, PricingStateType } from '../../../constants/PricingStateType'

export default function PricingDetail({ selectedPricingLibraryID, selectedPricingID }) {
    const approxAPI = ApproxAPIClient();
    const [pricing, setPricing] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!selectedPricingID || !selectedPricingLibraryID) return;

        const fetchPricing = async () => {
            setIsLoading(true);
            try {
                const response = await approxAPI.fetchPricingByID(selectedPricingLibraryID, selectedPricingID);
                if (response.isSuccessful) {
                    setPricing(response.data);
                } else {
                    alert(response.error);
                }
            } catch (error) {
                console.error('Error fetching pricing:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPricing();
    }, [selectedPricingID, selectedPricingLibraryID, approxAPI]);

    const PricingRow = ({ row }) => (
        <>
            {/* Main Row */}
            <TableRow>
                <TableCell align="center">{row.code}</TableCell>
                <TableCell align="left">{row.definition}</TableCell>
                <TableCell align="center">{PricingUnitValTypeDict[row.pricingUnit]}</TableCell>
            </TableRow>

            {/* Description Row */}
            {row.description && (
                <TableRow>
                    <TableCell colSpan={3} sx={{ padding: 2, fontStyle: 'italic', backgroundColor: '#f9f9f9' }}>
                        {row.description}
                    </TableCell>
                </TableRow>
            )}

            {/* Child Items with Subtotals */}
            <TableRow>
                <TableCell colSpan={3} sx={{ padding: 0 }}>
                    <Collapse in timeout="auto" unmountOnExit>
                        {row.items && row.items.length > 0 ? (
                            <Table size="small" aria-label="sub-items">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Code</TableCell>
                                        <TableCell align="left">Definition</TableCell>
                                        <TableCell align="center">Unit</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="center">Unit Price</TableCell>
                                        <TableCell align="center">Line Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((subItem, index) => (
                                        <React.Fragment key={index}>
                                            <TableRow>
                                                <TableCell align="center">{subItem.code}</TableCell>
                                                <TableCell align="left">{subItem.definition}</TableCell>
                                                <TableCell align="center">{PricingUnitValTypeDict[subItem.pricingUnit]}</TableCell>
                                                <TableCell align="center">{subItem.amount.toFixed(2)}</TableCell>
                                                <TableCell align="center">{subItem.unitPrice.toFixed(2)}</TableCell>
                                                <TableCell align="center">{subItem.lineTotal.toFixed(2)}</TableCell>
                                            </TableRow>
                                            {subItem.percentagePricing !== null && (
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell align="left" colSpan={4}>{`${subItem.percentagePricing.definition} (${subItem.percentagePricing.percentage})`}</TableCell>
                                                    <TableCell align="center">{subItem.percentagePricing.price.toFixed(2)}</TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}

                                    {/* Subtotal Row */}
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.rawUnitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Contractor Profit Percentage ({row.contractorProfitPercentage.toFixed(2)})
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.contractorProfitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Unit Price
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.unitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        ) : (
                            <Table size="small" aria-label="sub-items">
                                <TableBody>
                                    {/* Subtotal Row */}
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Subtotal
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.rawUnitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Contractor Profit Percentage ({row.contractorProfitPercentage.toFixed(2)})
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.contractorProfitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={5} align="right" sx={{ fontWeight: 'bold' }}>
                                            Unit Price
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                                            {row.unitPrice.toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );


    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography variant="h6" color="text.secondary">
                    Pricing Detail
                </Typography>
                {pricing ? <Typography variant="h6" color="text.secondary">
                    <Chip color={pricing.pricingState === PricingStateType.Validated ? 'success' : 'warning'} label={PricingStateValTypeDict[pricing.pricingState]} />
                </Typography> : <></>}
            </Stack>
            {!selectedPricingLibraryID ? (
                <Typography color="text.secondary" textAlign="center">
                    Please select a pricing library!
                </Typography>
            ) : !selectedPricingID ? (
                <Typography color="text.secondary" textAlign="center">
                    Please select a pricing!
                </Typography>
            ) : isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <CircularProgress />
                </Box>
            ) : pricing ? (
                <TableContainer component={Paper} sx={{ maxHeight: '90vh' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Code</TableCell>
                                <TableCell align="left">Definition</TableCell>
                                <TableCell align="center">Unit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <PricingRow row={pricing} />
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography color="text.secondary" textAlign="center">
                    No data available.
                </Typography>
            )}
        </Paper>
    );
}
