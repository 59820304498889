import React, { useEffect, useState, useCallback } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Skeleton from '@mui/material/Skeleton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import ApproxAPIClient from '../../../ApproxAPIClient';
import LabelIcon from '@mui/icons-material/Label';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import LibraryType from '../../../constants/LibraryType';
import CreatePricingLibraryDialog from '../components/CreatePricingLibraryDialog';
import { v4 as guid } from 'uuid';

export default function PricingLibraryList({ handleResetPricingLibrarySelect, handlePricingLibrarySelect, selectedPricingLibraryID, selectedValidDate }) {
    const approxAPIClient = ApproxAPIClient();
    const [libraries, setLibraries] = useState([]);
    const [isLoading, setIsLoading] = useState();

    const [createPricingLibraryIsOpen, setCreatePricingLibraryIsOpen] = useState(false);
    const [pricingLibraryListRenderID, setPricingLibraryListRenderID] = useState(guid())

    const handleCreateProjectClickOpen = () => {
        setCreatePricingLibraryIsOpen(true);
    };

    const handleCreatePricingLibrarySuccess = () => {
        setPricingLibraryListRenderID(guid());
    }

    useEffect(() => {
        try {
            setIsLoading(true);
            approxAPIClient.fetchLibraries().then((response) => {
                if (response.isSuccessful === true) {
                    setLibraries(response.data);
                } else {
                    alert(response.error);
                }
            }).then(() => {
                setIsLoading(false);
            });
        } catch (error) {
            console.error('Error fetching library list:', error);
        }
    }, [approxAPIClient, pricingLibraryListRenderID])

    const handleValidDateClick = useCallback((e, library, validDate) => {
        // Select the library when a valid date is clicked
        handlePricingLibrarySelect(library.id, validDate);
    }, [handlePricingLibrarySelect]);

    const handlePricingLibraryDelete = async (pricingLibraryID) => {
        setIsLoading(true);
        try {
            await approxAPIClient.deletePricingLibrary(pricingLibraryID).then((response) => {
                if (response.isSuccessful === true) {
                    const others = libraries.filter((x) => x.id !== pricingLibraryID);
                    setLibraries([...others]);
                    handleResetPricingLibrarySelect();
                } else {
                    alert(response.error);
                }
            })
        } catch (error) {
            alert(error);
        }

        setIsLoading(false);
    }

    return (
        <Paper style={{ height: '100%' }}>
            <CreatePricingLibraryDialog
                createPricingLibraryIsOpen={createPricingLibraryIsOpen}
                setCreatePricingLibraryIsOpen={setCreatePricingLibraryIsOpen}
                handleCreatePricingLibrarySuccess={handleCreatePricingLibrarySuccess}
            />
            <List
                component="nav"
                aria-label="project list"
                style={{ height: '100%', overflowX: 'hidden', overflowY: 'auto' }}
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        <Stack spacing={1} direction="row" sx={{ justifyContent: 'space-between', padding: 1 }}>
                            <Typography sx={{ height: "100%", lineHeight: "200%" }} color="text.secondary" textAlign="left">
                                Libraries
                            </Typography>
                            <Button variant="outlined" size="small" onClick={handleCreateProjectClickOpen}>Create</Button>
                        </Stack>
                    </ListSubheader>
                }
            >
                {!isLoading && libraries && libraries.length > 0 ? libraries.map(library =>
                    <div key={library.id}>
                        <ListItemButton
                            selected={library.id === selectedPricingLibraryID}
                        >
                            <ListItemIcon>
                                <LabelIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={library.name}
                                primaryTypographyProps={{
                                    fontSize: 15,
                                    fontWeight: 'medium',
                                    lineHeight: '20px',
                                    mb: '2px',
                                    color: 'black'
                                }}
                            />
                            <IconButton edge="end" aria-label="delete" disabled={library.libraryType !== LibraryType.Custom} onClick={() => handlePricingLibraryDelete(library.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemButton>
                        {/* Rendering valid dates under the library */}
                        <List component="div" disablePadding>
                            {library.validDates && library.validDates.length > 0 ? library.validDates.map((validDate, index) =>
                                <ListItemButton
                                    key={`${library.id}#${index}`}
                                    selected={selectedPricingLibraryID === library.id && validDate.validFrom === selectedValidDate.validFrom && validDate.validTo === selectedValidDate.validTo}
                                    onClick={(e) => handleValidDateClick(e, library, validDate)} // Trigger library selection when valid date is clicked
                                    sx={{ pl: 4 }}
                                >
                                    <ListItemText
                                        primary={`${validDate.validFrom} - ${validDate.validTo}`}
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'normal',
                                            lineHeight: '20px',
                                            mb: '2px',
                                            color: 'black'
                                        }}
                                    />
                                </ListItemButton>
                            ) : (
                                <Typography sx={{ height: "10%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                                    No valid dates available
                                </Typography>
                            )}
                        </List>
                    </div>
                ) : !isLoading && libraries.length === 0 ? (
                    <Typography sx={{ height: "10%", lineHeight: "200%" }} color="text.secondary" textAlign="center">
                        There is no library!
                    </Typography>
                ) : (
                    <Box sx={{ width: '100%', padding: '5%' }}>
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </Box>
                )}
            </List>
        </Paper>
    );
}