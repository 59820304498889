import React, { useState } from 'react';
import Layout from '../common/components/Layout';
import Grid from '@mui/material/Grid';
import PricingLibraryList from '../pricing/components/PricingLibraryList';
import PricingList from '../pricing/components/PricingList';
import PricingDetail from '../pricing/components/PricingDetail';

export default function PricingPage() {
    const [selectedPricingLibraryID, setSelectedPricingLibraryID] = useState();
    const [selectedPricingID, setSelectedPricingID] = useState();
    const [validDate, setValidDate] = useState();

    const handlePricingLibrarySelect = (pricingLibraryID, validDate) => {
        setSelectedPricingLibraryID(pricingLibraryID);
        setSelectedPricingID(undefined);
        setValidDate(validDate);
    }

    const handleResetPricingLibrarySelect = () => {
        setSelectedPricingLibraryID(undefined);
        setSelectedPricingID(undefined);
        setValidDate(undefined);
    }

    const handlePricingListClick = (id) => {
        setSelectedPricingID(id);
    }

    return (
        <Layout>
            <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid item xs={2}>
                    <PricingLibraryList
                        handleResetPricingLibrarySelect={handleResetPricingLibrarySelect}
                        handlePricingLibrarySelect={handlePricingLibrarySelect}
                        selectedPricingLibraryID={selectedPricingLibraryID}
                        selectedValidDate={validDate}
                    />
                </Grid>
                <Grid item xs={4} style={{ height: '100%' }}>
                    <PricingList
                        selectedPricingLibraryID={selectedPricingLibraryID}
                        setSelectedPricingID={handlePricingListClick}
                        selectedValidDate={validDate}
                    />
                </Grid>
                <Grid item xs={6} style={{ height: '100%' }}>
                    <PricingDetail
                        selectedPricingLibraryID={selectedPricingLibraryID}
                        selectedPricingID={selectedPricingID}
                    />
                </Grid>
            </Grid>
        </Layout>
    );
}