import React from 'react';
import TakeOffPage from './pages/takeoff/TakeOffPage';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LogoutPage from "./pages/logout/LogoutPage";
import MainPage from './pages/main/MainPage';
import AdminPage from './pages/admin/AdminPage';
import WorkOrderPage from './pages/workOrder/WorkOrderPage'
import PricingPage from './pages/pricing/PricingPage'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="/takeoff" element={<TakeOffPage />} />
        <Route path="/workOrder" element={<WorkOrderPage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/pricing' element={<PricingPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default withAuthenticationRequired(App);