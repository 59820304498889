const PricingUnitType = {
    None: 0,
    m: 1,
    m2: 2,
    _100m2: 3,
    _1000m2: 4,
    m3: 5,
    cm3: 6,
    qty: 7,
    _1000qty: 8,
    t: 9,
    kg: 10,
    package: 11,
    kwh: 12,
    lt: 13,
    hour: 14,
    set: 15
};

const PricingUnitTypeDict = {
    None: "-",
    m: "m",
    m2: "m²",
    _100m2: "100 m²",
    _1000m2: "1000 m²",
    m3: "m³",
    cm3: "cm³",
    qty: "qty",
    _1000qty: "1000 qty",
    t: "t",
    kg: "kg",
    package: "pack",
    kwh: "kwh",
    lt: "lt",
    hour: "h",
    set: "set"
}

const PricingUnitValTypeDict = {
    0: PricingUnitTypeDict["None"],
    1: PricingUnitTypeDict["m"],
    2: PricingUnitTypeDict["m2"],
    3: PricingUnitTypeDict["_100m2"],
    4: PricingUnitTypeDict["_1000m2"],
    5: PricingUnitTypeDict["m3"],
    6: PricingUnitTypeDict["cm3"],
    7: PricingUnitTypeDict["qty"],
    8: PricingUnitTypeDict["_1000qty"],
    9: PricingUnitTypeDict["t"],
    10: PricingUnitTypeDict["kg"],
    11: PricingUnitTypeDict["package"],
    12: PricingUnitTypeDict["kwh"],
    13: PricingUnitTypeDict["lt"],
    14: PricingUnitTypeDict["hour"],
    15: PricingUnitTypeDict["set"],
}

export { PricingUnitType, PricingUnitTypeDict, PricingUnitValTypeDict };