import React from 'react';
import Layout from '../common/components/Layout';
import Retool from 'react-retool';


export default function AdminPage() {

    return (
        <Layout>
            <Retool
                url="https://approx.retool.com/apps/Home"
                width={"100%"}
                height={"100%"}
            />
        </Layout>
    );
}