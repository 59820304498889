import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Box,
  Pagination as MUIPagination,
  Chip,
  Typography,
  Stack,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton
} from "@mui/material";
import { Add, Clear, PlayArrow } from "@mui/icons-material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  QueryBuilder,
  NumericFilter,
  StringFilter,
  BooleanFilter,
  DateFilter,
  NumericFilterOperation,
  StringFilterOperation,
  BooleanFilterOperation,
  DateFilterOperation,
  Pagination,
  SortField,
  SortDirection
} from "dynamic-query-builder-client";

const DQBFilterComponent = ({ columns, setQuery, count, pageSize, dataTableComponent }) => {
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnNameInputValue, setColumnNameInputValue] = useState("");
  const [columnTypeInputValue, setColumnTypeInputValue] = useState("");
  const [filterOperationInputValue, setFilterOperationInputValue] = useState("");
  const [filterValueInputValue, setFilterValueInputValue] = useState("");
  const [sortByColumn, setSortByColumn] = useState("None");
  const [sortDirection, setSortDirection] = useState(SortDirection.ASC);
  const [accOpen, setAccOpen] = useState(false);

  const getFilterOperationTypeByColumnType = (columnType) => {
    switch (columnType) {
      case "Numeric":
        return NumericFilterOperation;
      case "String":
        return StringFilterOperation;
      case "Boolean":
        return BooleanFilterOperation;
      case "Date":
        return DateFilterOperation;
      case "Enum":
        return BooleanFilterOperation;
      default:
        return undefined;
    }
  }

  const buildQueryString = useCallback(() => {
    const dynamicFilters = filters.map((filter) => {
      switch (filter.type) {
        case "Numeric":
          return new NumericFilter({
            property: filter.column,
            value: Number(filter.value),
            op: NumericFilterOperation[filter.operation],
          });
        case "String":
          return new StringFilter({
            property: filter.column,
            value: filter.value,
            op: StringFilterOperation[filter.operation],
          });
        case "Boolean":
          return new BooleanFilter({
            property: filter.column,
            value: filter.value === "true",
            op: BooleanFilterOperation[filter.operation],
          });
        case "Date":
          return new DateFilter({
            property: filter.column,
            value: new Date(filter.value).toISOString(),
            op: DateFilterOperation[filter.operation],
          });
        case "Enum":
          return new NumericFilter({
            property: filter.column,
            value: Number(filter.value),
            op: BooleanFilterOperation[filter.operation]
          })
        default:
          throw new Error("Unknown filter type");
      }
    });

    const sortOption = sortByColumn !== "None" && sortDirection !== SortDirection.NONE
      ? new SortField({
        property: sortByColumn,
        by: SortDirection[sortDirection],
      })
      : null;

    const queryBuilder = new QueryBuilder({
      filters: dynamicFilters,
      sortBy: sortOption,
      pagination: new Pagination({
        offset: (currentPage - 1) * pageSize,
        count: pageSize,
      }),
    });

    return queryBuilder.build();
  }, [currentPage, filters, pageSize, sortByColumn, sortDirection]);

  const resetInputState = () => {
    setColumnNameInputValue("");
    setColumnTypeInputValue("");
    setFilterOperationInputValue("");
    setFilterValueInputValue("");
  };

  const handleClearFilters = () => {
    resetInputState();
    setFilters([]);
    setSortByColumn("None");
    setSortDirection(SortDirection.ASC);
  };

  const addFilter = () => {
    if (!columnNameInputValue || !columnTypeInputValue || !filterOperationInputValue || !filterValueInputValue) {
      return;
    }

    setFilters((prevFilters) => [
      ...prevFilters,
      {
        id: Date.now().toString(),
        column: columnNameInputValue,
        type: columnTypeInputValue,
        operation: filterOperationInputValue,
        value: filterValueInputValue,
      },
    ]);

    resetInputState();
  };

  const handleColumnChanged = (columnName) => {
    setColumnNameInputValue(columnName);
  };

  useEffect(() => {
    const queryString = buildQueryString();
    setQuery(queryString);
  }, [currentPage, setQuery, buildQueryString])

  useEffect(() => {
    setCurrentPage(1);
  }, [filters])

  useEffect(() => {
    const columnInfo = columns.find((col) => col.name === columnNameInputValue);
    setColumnTypeInputValue(columnInfo?.type || "");
    setFilterOperationInputValue("");
    setFilterValueInputValue("");
  }, [columnNameInputValue, columns]);

  const removeFilter = (id) => {
    setFilters((prev) => prev.filter((filter) => filter.id !== id));
  };

  const handleApplyFilters = () => {
    const queryString = buildQueryString();
    setQuery(queryString);
    setAccOpen(false);
  };

  return (
    <Stack spacing={1} height={"100%"} direction="column" sx={{ justifyContent: 'start', padding: 1 }}>
      <Accordion expanded={accOpen} onChange={() => setAccOpen(!accOpen)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Filter & Sort
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ mt: 2 }} color="text.secondary">
            Filtering
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell padding="none" size="small" sx={{ width: "30%" }}>
                      <InputLabel id="column-name-select-label">Column Name</InputLabel>
                      <Select
                        labelId="column-name-select-label"
                        value={columnNameInputValue}
                        onChange={(e) => handleColumnChanged(e.target.value)}
                        size="small"
                        fullWidth
                      >
                        {columns.map((col) => (
                          <MenuItem hidden={col.isHidden} key={col.name} value={col.name}>
                            {col.readableName}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>

                    <TableCell padding="none" size="small" sx={{ width: "30%" }}>
                      <InputLabel id="filter-type-select-label">Filter Type</InputLabel>
                      <Select
                        value={filterOperationInputValue}
                        onChange={(e) => setFilterOperationInputValue(e.target.value)}
                        size="small"
                        fullWidth
                        labelId="filter-type-select-label"
                        disabled={columnTypeInputValue === ""}
                      >
                        {columnTypeInputValue ? Object.values(getFilterOperationTypeByColumnType(columnTypeInputValue)).map((op) => (
                          <MenuItem key={op} value={op}>
                            {op}
                          </MenuItem>
                        )) : <></>}
                      </Select>
                    </TableCell>

                    <TableCell padding="none" size="small" sx={{ width: "30%" }}>
                      <InputLabel id="filter-value-select-label">Filter Value</InputLabel>
                      {columnTypeInputValue === "Numeric" && (
                        <TextField
                          type="number"
                          label="Filter Value"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={filterValueInputValue}
                          onChange={(e) => setFilterValueInputValue(e.target.value)}
                        />
                      )}
                      {columnTypeInputValue === "String" && (
                        <TextField
                          type="text"
                          label="Filter Value"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={filterValueInputValue}
                          onChange={(e) => setFilterValueInputValue(e.target.value)}
                        />
                      )}
                      {columnTypeInputValue === "Boolean" && (
                        <Select
                          labelId="filter-value-select-label"
                          value={filterValueInputValue}
                          onChange={(e) => setFilterValueInputValue(e.target.value)}
                          size="small"
                          fullWidth
                        >
                          <MenuItem value="true">True</MenuItem>
                          <MenuItem value="false">False</MenuItem>
                        </Select>
                      )}
                      {columnTypeInputValue === "Date" && (
                        <TextField
                          label="Filter Value"
                          type="date"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={filterValueInputValue}
                          onChange={(e) => setFilterValueInputValue(e.target.value)}
                        />
                      )}
                      {columnTypeInputValue === "Enum" && (
                        <Select
                          labelId="filter-value-select-label"
                          value={filterValueInputValue}
                          onChange={(e) => setFilterValueInputValue(e.target.value)}
                          size="small"
                          fullWidth
                        >
                          {

                            Object.keys(columns.find((x) => x.name === columnNameInputValue).enumType).map((key) => {
                              return (<MenuItem value={columns.find((x) => x.name === columnNameInputValue).enumType[key]}>
                                {columns.find((x) => x.name === columnNameInputValue).enumTypeDict[key]}
                              </MenuItem>)
                            })
                          }
                        </Select>
                      )}
                      {columnTypeInputValue === "" && (
                        <TextField
                          type="text"
                          label="Filter Value"
                          variant="outlined"
                          size="small"
                          fullWidth
                          value={""}
                          disabled={true}
                        />
                      )}
                    </TableCell>
                    <TableCell padding="none" size="large" sx={{ width: "5%" }}>
                      <IconButton
                        aria-label="add"
                        size="small"
                        disabled={columnNameInputValue === "" || filterOperationInputValue === "" || filterValueInputValue === ""}
                        onClick={addFilter}
                      >
                        <Add />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>

          {/* Sort By Section */}
          <Typography sx={{ mt: 2 }} color="text.secondary">
            Sorting
          </Typography>
          <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
            <Select
              label="Sort By"
              value={sortByColumn}
              onChange={(e) => setSortByColumn(e.target.value)}
              size="small"
              fullWidth
            >
              <MenuItem value="None">None</MenuItem>
              {columns.map((col) => (
                <MenuItem hidden={col.isHidden} key={col.name} value={col.name}>
                  {col.readableName}
                </MenuItem>
              ))}
            </Select>
            <Select
              label="Sort Direction"
              value={sortDirection}
              onChange={(e) => setSortDirection(e.target.value)}
              size="small"
              fullWidth
              disabled={!sortByColumn}
            >
              <MenuItem value={SortDirection.ASC}>{SortDirection.ASC}</MenuItem>
              <MenuItem value={SortDirection.DESC}>{SortDirection.DESC}</MenuItem>
            </Select>
          </Stack>

          {/* Filters Section */}
          <Typography sx={{ mt: 2 }} color="text.secondary">
            Filters
          </Typography>
          <Box height={"60px"}>
            {filters.map((filter) => {
              var col = columns.find((x) => x.name === filter.column);
              return (<Chip
                key={filter.id}
                label={`${col.readableName} ${filter.operation} ${filter.value}`}
                onDelete={() => removeFilter(filter.id)}
                color="primary"
                variant="outlined"
                sx={{ m: 0.5 }}
              />
              )
            })}
          </Box>

          {/* Pagination and Apply Filters */}
          <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleClearFilters}
              startIcon={<Clear />}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleApplyFilters}
              startIcon={<PlayArrow />}
            >
              Apply Filters
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {dataTableComponent}
      {/* Pagination */}
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 3 }}>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
          Total Data: {count} | Page Size: {pageSize}
        </Typography>
        <MUIPagination
          count={Math.ceil(count / pageSize)}
          page={currentPage}
          onChange={(event, value) => setCurrentPage(value)}
          color="primary"
        />
      </Box>
    </Stack>
  );
};

export default DQBFilterComponent;
