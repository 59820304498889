import React, { useState, useEffect } from "react";
import DQBFilterComponent from "./DQBFilterComponent";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

const DQBDataGrid = ({ columns, apiClientMethod, apiClientParams, pageSize, handleRowSelect }) => {
    const [query, setQuery] = useState(`&offset=0&count=${pageSize}`);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(pageSize);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRowID, setSelectedRowID] = useState();

    useEffect(() => {
        const callApi = async () => {
            if (!query) {
                return;
            }
            setIsLoading(true);
            const input = { ...apiClientParams, query: query };

            try {
                const response = await apiClientMethod(input);
                setCount(response?.data?.count ?? pageSize);
                setItems(response?.data?.data ?? []);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        };

        callApi();
    }, [query, apiClientMethod, pageSize, apiClientParams]);

    const handleSetQuery = (query) => {
        setQuery(query);
    };

    const handleRowClick = (selectedID) => {
        setSelectedRowID(selectedID);
        handleRowSelect(selectedID);
    }

    const renderTable = () => {
        return !isLoading ? <TableContainer
            component={Paper}
            sx={{
                height: '750px',
                marginTop: 2,
                overflow: 'hidden'
            }}
        >
            <Table
                sx={{
                    height: '100%',
                    '& .MuiTableRow-root': {
                        height: '10%',
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell
                                hidden={column.isHidden}
                                key={column.name}
                                sx={{
                                    fontWeight: 'bold',
                                    backgroundColor: '#f0f0f0',
                                    textAlign: 'center',
                                }}
                            >
                                {column.readableName}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <TableRow
                                key={index}
                                hover
                                selected={selectedRowID === item.id}
                                onClick={() => handleRowClick(item.id)}
                                sx={{
                                    backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff',
                                    '&:hover': {
                                        backgroundColor: '#e0f7fa',
                                    },
                                }}
                            >
                                {columns.map((column) => (
                                    <TableCell
                                        hidden={column.isHidden}
                                        key={column.name}
                                        sx={{
                                            textAlign: column.type === 'Numeric' ? 'right' : 'left',
                                            padding: '8px',
                                        }}
                                    >
                                        {item[column.name] !== undefined
                                            ? (column.type === 'Date'
                                                ? new Date(item[column.name]).toLocaleDateString("en-US")
                                                : column.type === 'Numeric'
                                                    ? Number(item[column.name]).toFixed(2)
                                                    : column.type === 'Enum'
                                                        ? column.enumTypeDict[
                                                        Object.entries(column.enumType).find(
                                                            (key, value) => value === item[column.name]
                                                        )[0]
                                                        ]
                                                        : String(item[column.name])
                                            )
                                            : "-"}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} align="center">
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
            :
            <Box sx={{ width: '100%', padding: '5%' }}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
            </Box>
    }

    const table = renderTable();
    return (
        <DQBFilterComponent
            columns={columns}
            count={count}
            setQuery={handleSetQuery}
            pageSize={pageSize}
            dataTableComponent={table}
        />
    );
};

export default DQBDataGrid;